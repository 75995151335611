<script lang="ts">
  import { type Options, Splide } from "@splidejs/svelte-splide";
  import TestimonialSplideSlide from "./testimonial-splide-slide.svelte";
  import "@splidejs/svelte-splide/css";

  const options: Options = {
    type: "loop",
    focus: "center",
    width: "92rem",
    gap: "2em",
    autoWidth: true,
    autoplay: true,
    interval: 10e3,
  };
</script>

<Splide class="mx-auto" {options}>
  <TestimonialSplideSlide name="Daniel Hill">
    Our experience with UTVFX Graphics was amazing to say the least. We had great design ideas and
    [their] team helped us the whole way through. The website they offer is easy [to] navigate and
    design your own personal build. Super easy to DIY install as well. Thank you UTVFX.
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Tristan Decker">
    These are the easiest graphics I’ve ever put on. I made a mistake [while ordering] and they are
    the ones that caught it and personally called me to figure the situation out. These people go
    above and beyond and I couldn't be happier with the end result.
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Hannah Thornton">
    I cannot speak enough about the quality of UTVFX Graphics!&hellip; easy to put on, looks
    amazing, and helps protect the longevity of our machine.&hellip; The team is extremely
    thoughtful and willing to work with you at every step of the way to guarantee you get exactly
    what you want! Through mud, water, dust, etc. it has never started to come up at any spot.
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Toby Davies">
    Honestly the best experience with online ordering, communication and then the finished product.
    A few extra rolls of wrap added to help with my Polaris Ranger clear doors. Then easy install,
    and the quality and finish&hellip; am sooooo happy, had changed the vehicle and everyone
    comments.&hellip;
  </TestimonialSplideSlide>
  <TestimonialSplideSlide name="Jim Hulm">
    I have ordered several custom wraps from [UTVFX Graphics] to include sled wraps and a wrap for
    our RZR Pro R. [Their] service is top notch and [the product] is second to none! If you are
    thinking about doing a wrap, quit thinking and give them a call!
  </TestimonialSplideSlide>
</Splide>
